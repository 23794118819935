import React, {FC} from 'react';

interface EmptyPageProps {
  principalCategorySlug?: string;
}

const EmptyPage: FC<EmptyPageProps> = ({principalCategorySlug}) => (
  <div className="reorder-page__empty">
    <a href={`/${principalCategorySlug ?? ''}`} className="button">
      Shop the Market
    </a>
    <p className="reorder-page__empty_text">You’ll be able to shop from previous orders here.</p>
  </div>
);

export default EmptyPage;
