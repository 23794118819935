import React, {FC} from 'react';
import {useSelector} from 'react-redux';

import MarketTile from 'web/components/market_tile';

import {StoreData} from '../../controller';

const segmentFeature = 'reorder';

interface OrderItemProps {
  productId: string;
  position: number;
}

const OrderItem: FC<OrderItemProps> = ({productId, position}) => {
  const {
    basketItemsByProductId,
    currentFulfillmentDay,
    favorites,
    fulfillmentDaySummaries,
    products,
  } = useSelector((s: StoreData) => ({
    products: s.products,
    basketItemsByProductId: s.basketItemsByProductId,
    favorites: s.favorites,
    currentFulfillmentDay: s.currentFulfillmentDay,
    fulfillmentDaySummaries: s.fulfillmentDaySummaries,
  }));

  const productItem = products[productId];

  if (productItem == null) {
    return null;
  }

  let quantityInBasket = 0;
  quantityInBasket = basketItemsByProductId?.[productId]
    ? basketItemsByProductId[productId].quantity
    : 0;

  return (
    <MarketTile
      key={`${productId}-${position}`}
      currentFulfillmentDay={currentFulfillmentDay}
      fulfillmentDaySummaries={fulfillmentDaySummaries}
      productId={productItem.id}
      position={position}
      segmentFeature={segmentFeature}
      quantityInBasket={quantityInBasket}
      isFavorited={favorites.includes(productId)}
    />
  );
};

export default OrderItem;
