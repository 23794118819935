import React from 'react';
import {Helmet} from 'react-helmet-async';
import {useSelector} from 'react-redux';

import moment from 'infra/moment';
import useClientSettings from 'web/hooks/useClientSettings';
import MarketLayout, {reducer as marketLayoutReducer} from 'web/components/market_layout';
import {PageType} from 'web/helpers/redux_client';

import {StoreData} from './controller';
import OrderItem from './components/order_item';
import EmptyState from './components/empty';

const ReorderPage: PageType<StoreData> = () => {
  const clientSettings = useClientSettings();
  const {orders, principalCategorySlug, recommendedProducts} = useSelector((s: StoreData) => ({
    orders: s.orders,
    principalCategorySlug: s.principalCategorySlug,
    recommendedProducts: s.recommendedProducts,
  }));

  const isEmpty = orders.length === 0;

  return (
    <MarketLayout>
      <Helmet>
        <title>Reorder | Good Eggs</title>
      </Helmet>

      <div className="reorder-page" data-attribution-feature="reorder">
        <div className="reorder-page__container">
          <div className="reorder-page__header">
            <h3>Reorder</h3>
          </div>
          {isEmpty ? (
            <EmptyState principalCategorySlug={principalCategorySlug} />
          ) : (
            orders.map((order) => (
              <div key={order.id}>
                <div className="reorder-page__grid__header">
                  <p>
                    Delivered{' '}
                    {moment
                      .tz(order.deliveryWindow.startAt, clientSettings.tzid)
                      .format('dddd, MMMM Do')}
                  </p>
                </div>
                <div className="reorder-page__grid">
                  {order.items.map((item, index) => (
                    <OrderItem key={item.id} productId={item.product.id} position={index} />
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
        <div className="reorder-page__container">
          <div className="reorder-page__header">
            <h3>Recommendations</h3>
          </div>
          <div>
            <div className="reorder-page__grid__header">
              <p>Based on your shopping</p>
            </div>
            <div className="reorder-page__grid">
              {recommendedProducts.map((recommendedProduct, index) => (
                <OrderItem
                  key={recommendedProduct.id}
                  productId={recommendedProduct.id}
                  position={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </MarketLayout>
  );
};

ReorderPage.pageName = 'Reorder';
ReorderPage.reducer = (state, action) => {
  if (!state) throw new Error('State should always be preloaded here');
  return marketLayoutReducer(state, action);
};

export default ReorderPage;
